module.exports = [{
      plugin: require('F:/GithubRepos/PhoenixGyaan-branch-blogV2/phoenixgyaan.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-90245818-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('F:/GithubRepos/PhoenixGyaan-branch-blogV2/phoenixgyaan.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('F:/GithubRepos/PhoenixGyaan-branch-blogV2/phoenixgyaan.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('F:/GithubRepos/PhoenixGyaan-branch-blogV2/phoenixgyaan.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
